import { isPreviewingAnimationAtom } from '@atoms/animation';
import { addAnimation, getAnimationClassesFromDocument } from '@helpers/animation';
import { AnimationObject } from '@models/animation';
import { observeElementIntersect } from '@web-for-marketing/react-ui';
import { useSetAtom } from 'jotai';
import { useEffect, useRef } from 'react';

export function useAnimationObserver(change: boolean, resetOnChange = false): void {
    const animations = useRef<AnimationObject>({ containers: [], triggers: [] });
    const setPreviewingAnimation = useSetAtom(isPreviewingAnimationAtom);

    useEffect(() => {
        setPreviewingAnimation(false);
    }, [setPreviewingAnimation]);

    useEffect(() => {
        animations.current = getAnimationClassesFromDocument(document);
        observeElementIntersect(animations.current.triggers, (target: Element) =>
            addAnimation(animations.current, target, { resetOnChange })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [change]);
}
