import { AnimationObject, Options } from '@models/animation';

export const getAnimationClassesFromDocument = (currentDocument: Document): AnimationObject => ({
    containers: [].slice.call(currentDocument.querySelectorAll('.animateContainers')),
    triggers: [].slice.call(currentDocument.querySelectorAll('.toAnimateTrigger')),
});

export const addAnimation = (allAnimationClasses: AnimationObject, target: Element, options?: Options): void => {
    const currentContainer = target.closest('.animateContainers');
    if (currentContainer && allAnimationClasses) {
        const allToAnimate: HTMLElement[] = [].slice.call(currentContainer.querySelectorAll('.toAnimate'));

        allToAnimate.forEach((child, index) => {
            setTimeout(() => {
                child.style.animationDelay = `${index * 200}ms`;
                child.classList.remove('animateHidden');
                child.classList.add('animated');
                if (options?.resetOnChange) {
                    child.style.opacity = '1';
                }
            }, 250);
        });
        if (!options?.resetOnChange) {
            allAnimationClasses.containers.shift();
            allAnimationClasses.triggers.shift();
        }
    }
};
