import React from 'react';

export function ShortArrow(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 21 21'
            width='1em'
            height='1em'
            fill='none'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            color='#66788C'
            {...props}
        >
            <path
                xmlns='http://www.w3.org/2000/svg'
                d='M19.0093 10.9909L2.33569 10.9909M2.33569 10.9909L8.90055 17.5558M2.33569 10.9909L8.90055 4.42606'
                stroke='currentColor'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}
