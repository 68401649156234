import { v2Colors } from '@web-for-marketing/react-ui';

export const mobileDemoBannerStyles = {
    hideBanner: {
        opacity: 0,
        visibility: 'hidden',
    },
    mobileDemoButtonContainer: {
        position: 'fixed',
        width: '100%',
        backgroundColor: v2Colors.background.light,
        textAlign: 'center',
        zIndex: 100,
        bottom: 0,
        opacity: 1,
        transition: 'all 0.5s ease',
    },
    mobileDemoButton: {
        margin: '1rem',
        minHeight: '0',
        height: '4.8rem',
        width: 'fit-content !important',
        padding: '1rem 5rem !important',
    },
} as const;
