import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

export function Redirect({ redirectUrl, statusCode }) {
    if (statusCode === 200 && typeof window !== 'undefined') {
        window.location.href = redirectUrl;
    }

    return <Navigate to={redirectUrl} replace />;
}

Redirect.propTypes = {
    redirectUrl: PropTypes.string.isRequired,
    statusCode: PropTypes.number.isRequired,
};
