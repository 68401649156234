export function scrollToRef(ref) {
    if (typeof window !== 'undefined') {
        window.scrollTo(0, ref.offsetTop);
    }
}

export function smoothScrollToRef(ref) {
    if (typeof window !== 'undefined') {
        window.scrollTo({
            top: ref.current.offsetTop,
            left: 0,
            behavior: 'smooth',
        });
    }
}

export function ensureAnchorIsLoaded() {
    if (window && window.location && window.location.hash) {
        setTimeout(() => {
            const hash = window.location.hash;
            window.location.hash = '';
            window.location.hash = hash;
        }, 300);
    }
}
